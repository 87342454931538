<template>
  <div>
    <div class="loading" v-if="event_data === undefined && !event_error">
      <Loading />
    </div>
    <div v-if="event_data">
      <div class="page-title-area event-details" data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">
        <div class="container">
          <div class="page-title-content">
            <h2>{{event_data.data[0].title}}</h2>
            <p>{{moment(event_data.data[0].start_date)}} {{event_data.data[0].start_time ? '@ '+ event_data.data[0].start_time : ''}}</p>
          </div>
        </div>
      </div>

      <section class="blog-details-area ptb-100" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 col-md-12">
              <div class="blog-details-desc">
                <div class="article-content">
                  <div class="article-image">
                    <DefaultImage :id="event_data.data[0].image" style="min-height: 200px;" />
                  </div>

                  <div class="d-flex flex-column mb-3">
                    <p class="events-date mb-1">
                      <i class="fa fa-clock-o mr-1"></i>
                      <span v-if="event_data.data[0].start_date">{{moment(event_data.data[0].start_date)}} {{event_data.data[0].start_time ? '@ '+ event_data.data[0].start_time : ''}}</span>
                      <span v-if="event_data.data[0].end_date && event_data.data[0].end_date !== event_data.data[0].start_date"> - {{moment(event_data.data[0].end_date)}} {{event_data.data[0].end_time ? '@ '+ event_data.data[0].end_time : ''}}</span>
                    </p>
                    <p class="events-date" v-if="event_data.data[0].location">
                      <i class="fa fa-crosshairs mr-1"></i>{{event_data.data[0].location}}
                    </p>
                  </div>

                  <p class="white-space-pre-wrap" v-html="event_data.data[0].text"></p>
                </div>

                <div class="article-footer">
                  <div class="article-tags">
                    <span><i class='fa fa-share-alt'></i></span>

                    <a>Share</a>
                  </div>

                  <div class="article-share">
                    <ul class="social">
                      <li>
                        <ShareNetwork
                                network="facebook"
                                :url="base_url"
                                :title="event_data.data[0].title"
                                :description="event_data.data[0].description"
                        >
                          <i class="flaticon-facebook" aria-hidden="true"></i>
                        </ShareNetwork>
                      </li>
                      <li>
                        <ShareNetwork
                                network="twitter"
                                :url="base_url"
                                :title="event_data.data[0].title"
                                :description="event_data.data[0].description"
                        >
                          <i class="flaticon-twitter"></i>
                        </ShareNetwork>
                      </li>
                      <li>
                        <ShareNetwork
                                network="linkedin"
                                :url="base_url"
                                :title="event_data.data[0].title"
                                :description="event_data.data[0].description"
                        >
                          <i class="flaticon-linkedin"></i>
                        </ShareNetwork>
                      </li>
                      <li>
                        <ShareNetwork
                                network="email"
                                :url="base_url"
                                :title="event_data.data[0].title"
                                :description="event_data.data[0].description"
                        >
                          <i class="fa fa-envelope"></i>
                        </ShareNetwork>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-12">
              <aside class="widget-area" id="secondary">
                <section class="widget widget-peru-posts-thumb">
                  <h3 class="widget-title">Upcoming Events</h3>
                  <div class="post-wrap">
                    <div v-if="upcomingEvents_data === undefined && !upcomingEvents_error" class="loading two">
                      <Loading />
                    </div>
                    <div v-if="upcomingEvents_data">
                      <div class="not-found" v-if="upcomingEvents_data.data.length === 0">Not Found</div>
                      <article class="item" v-else v-for="upcoming in upcomingEvents_data.data" :key="upcoming.id">
                        <router-link :to="{ name: 'event', params: { url: upcoming.slug } }" class="thumb">
                          <DefaultImage :id="upcoming.image" :height="'80px'" :width="'80px'" />
                        </router-link>
                        <div class="info">
                          <time datetime="2020-06-30">{{moment(upcoming.start_date)}}</time>
                          <h4 class="title usmall">
                            <router-link :to="{ name: 'event', params: { url: upcoming.slug } }">
                              {{upcoming.title}}
                            </router-link>
                          </h4>
                        </div>
                        <div class="clear"></div>
                      </article>
                    </div>
                  </div>
                </section>
              </aside>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
  import api from "../services/api";
  import DefaultImage from "../components/DefaultImage";
  import Loading from "../components/Loading";
  import moment from "moment";
  import useSWRV from "swrv";

  export default {
    components: {
      Loading,
      DefaultImage
    },
    data() {
      return {
        base_url: window.location.href
      }
    },
    setup(props, { root }) {
      if (!root.$route.params.url) {
        root.$router.push({ name: '/' });
      }
      const {
        data: event_data,
        error: event_error,
        isValidating: event_isValidating
      } = useSWRV(() => `items/events?status=published&filter[slug]=${root.$route.params.url}`, api.fetcher);

      const {
        data: upcomingEvents_data,
        error: upcomingEvents_error,
        isValidating: upcomingEvents_isValidating
      } = useSWRV(() => `items/events?status=published&filter[end_date][gte]=${moment().format('YYYY-MM-DD')}&sort=end_date&limit=6`, api.fetcher);

      return {
        event_data,
        event_error,
        event_isValidating,

        upcomingEvents_data,
        upcomingEvents_error,
        upcomingEvents_isValidating
      };
    },
    methods: {
      params(newParams) {
        return {
          ...this.$route.params,
          ...newParams
        }
      },
      moment(date) {
        return moment(date).format('MMMM DD, YYYY');
      },
    },
    watch: {
      '$route.params': {
        immediate: true,
        handler() {
          window.scrollTo(0, 0);
        }
      }
    },
  }
</script>

<style scoped lang="scss">
  .loading {
    height: 800px;

    &.two {
      height: 300px;
    }
  }

  .page-title-content p {
    font-size: 20px;
    color: #FC4040;
  }

  .events-date {
    font-size: 1.2em;
    line-height: normal;
    color: #0f1031;

    i {
      width: 20px;
      color: #d74a2d;
    }
  }

  .social li {
    margin-right: 5px;
    cursor: pointer;
  }
</style>
